<template>
  <b-overlay :show="loading">
    <section>
      <b-row>
        <b-col md="12">
          <b-card>
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Judul" label-for="Judul">
                    <b-form-input id="Judul" v-model="dataSoal.name" />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="3">
                  <b-form-group label="Pilih Kategori Paket" label-for="paket_id">
                    <v-select
                      v-model="dataSoal.category_paket_id"
                      label="text"
                      :options="optpaket_id"
                      :reduce="(option) => option.value"
                    />
                  </b-form-group>
                </b-col> -->
                <!-- <b-col md="3">
                  <b-form-group label="Pilih Mata Pelajaran" label-for="mapel_id">
                    <v-select
                      v-model="dataSoal.mapel.id"
                      :options="optmapel_id"
                      label="text"
                      :reduce="(option) => option.value"
                    />
                  </b-form-group>
                </b-col> -->
                <b-col md="2">
                  <b-form-group label="Bobot Soal" label-for="bobot">
                    <b-form-input id="bobot" v-model="dataSoal.bobot" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Tipe Soal" label-for="type">
                    <v-select
                      v-model="dataSoal.type"
                      label="label"
                      :options="typesSoal"
                      :reduce="(option) => option.value"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" v-if="dataSoal.type == 'benar_salah'">
                  <b-form-group label="Jumlah Opsi Soal" label-for="opsi">
                    <v-select
                      v-model="selectedOption"
                      label="name"
                      :options="sum_options"
                      :reduce="(option) => option.id"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" v-if="dataSoal.type == 'pauli'">
                  <b-form-group label="Jumlah Opsi Soal" label-for="opsi">
                    <b-form-input v-model="selectedOption" />
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                  v-if="
                    dataSoal.type == 'pg' || dataSoal.type == 'multiple_choice'
                  "
                >
                  <b-form-group label="Jumlah Opsi Jawaban" label-for="opsi">
                    <v-select
                      v-model="selectedOption"
                      label="name"
                      :options="sum_options"
                      :reduce="(option) => option.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2" v-if="dataSoal.type == 'pg'">
                  <b-form-group label="Kunci Jawaban" label-for="opsi">
                    <v-select
                      v-model="is_correct"
                      label="label"
                      :options="masterOptions"
                      :reduce="(option) => option.value"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="2" v-if="dataSoal.type == 'multiple_choice'">
                  <b-form-group label="Kunci Jawaban" label-for="opsi">
                    <v-select multiple v-model="is_correct" label="label" :options="masterOptions"
                      :reduce="option => option.value" />
                  </b-form-group>
                </b-col> -->
                <!-- <b-col md="2">
                          <b-form-group label="opsi" label-for="opsi">
                              <v-select
              v-model="opsi"
              label="label"
              :options="opsisSoal"
              :reduce="option => option.value"
            />
                          </b-form-group>
                        </b-col> -->
              </b-row>
            </b-form>
            <b-tabs v-if="dataSoal.type != 'pauli'">
              <!-- Pertanyaan -->
              <b-tab active>
                <!-- title -->
                <template #title>
                  <!-- <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" /> -->
                  <span class="font-weight-bold">Pertanyaan</span>
                </template>
                <b-form>
                  <b-row>
                    <b-col>
                      <!-- <b-form-group label="Konten" label-for="Konten"> -->
                      <quill-editor
                        id="Konten"
                        v-model="dataSoal.content"
                        :options="editor_options"
                      >
                      </quill-editor>
                      <!-- </b-form-group> -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label class="font-weight-bold mt-2"
                        >Upload file format: jpg, png, pdf, doc, mp3, mp4
                        (opsional)</label
                      >
                      <b-form-file
                        id="Thumbnail"
                        v-model="dataSoal.file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        no-drop
                        accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                      />

                      <!-- <div v-if="dataSoal.file != null" class="flex justify-center">
                          <b-img :src="dataSoal.file" alt="Lampiran"  />
                        </div> -->
                      <!-- <div class="mt-4 mb-4" v-if="dataSoal.file != null">
                        <b-alert variant="primary" class="flex justify-between mb-4">
                          <p class="mb-4 flex dataSoals-center">Lampiran Soal : <a href="#">{{ dataSoal.file.name }}</a>
                            <a href="#" @click.prevent="unhandleFile(ind, $event)" class="ml-4">
                              <feather-icon icon="XIcon" svgClasses="sm text-danger"></feather-icon>
                            </a>
  
                          </p>
                        </b-alert>
                        <div v-if="dataSoal.preview_file != null" class="flex justify-center">
                          <b-img :src="dataSoal.preview_file" alt="Lampiran"  />
                        </div>
                      </div> -->
                    </b-col>
                    <b-col class="mt-1" md="12" v-if="dataSoal.file != null">
                      <!-- <strong>Thumbnail : </strong><br /> -->
                      <b-img
                        :src="apiFile + dataSoal.file.file"
                        style="max-width: 500px"
                        :alt="dataSoal.file.file"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <b-tab>
                <template #title>
                  <!-- <feather-icon icon="HelpCircleIcon" size="18" class="mr-50" /> -->
                  <span class="font-weight-bold">Pembahasan</span>
                </template>
                <b-form>
                  <b-row>
                    <b-col>
                      <!-- <b-form-group label="Konten" label-for="Konten"> -->
                      <quill-editor
                        id="Pembahasan"
                        v-model="dataSoal.pembahasan"
                        :options="editor_options"
                      />
                      <!-- </b-form-group> -->
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
            </b-tabs>
            <hr />
            <!-- Teks Jawaban PG, Multiple Choice, Bener-Salah -->
            <div
              v-if="
                dataSoal.type == 'pg' ||
                dataSoal.type == 'multiple_choice' ||
                dataSoal.type == 'benar_salah'
              "
            >
              <b-tabs class="mt-2">
                <div v-for="(data, index) in dataSoal.options" :key="index">
                  <b-tab>
                    <template #title>
                      <!-- <feather-icon icon="BookOpenIcon" size="18" class="mr-50" /> -->
                      <div>
                        <label class="font-weight-bold" v-if="index + 1 == 1"
                          >Opsi. A</label
                        >
                        <label class="font-weight-bold" v-if="index + 1 == 2"
                          >Opsi. B</label
                        >
                        <label class="font-weight-bold" v-if="index + 1 == 3"
                          >Opsi. C</label
                        >
                        <label class="font-weight-bold" v-if="index + 1 == 4"
                          >Opsi. D</label
                        >
                        <label class="font-weight-bold" v-if="index + 1 == 5"
                          >Opsi. E</label
                        >
                      </div>
                    </template>
                    <b-form>
                      <b-row>
                        <b-col>
                          <quill-editor
                            id="Konten"
                            v-model="data.content"
                            :options="editor_options"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- </b-form-group> -->
                        <!-- <b-form-group label="Konten" label-for="Konten"> -->
                        <b-col
                          md="2"
                          v-if="
                            dataSoal.type == 'multiple_choice' ||
                            dataSoal.type == 'benar_salah'
                          "
                        >
                          <label
                            class="font-weight-bold mt-2"
                            v-if="index + 1 == 1"
                            >Jawaban Opsi. A</label
                          >
                          <label
                            class="font-weight-bold mt-2"
                            v-if="index + 1 == 2"
                            >Jawaban Opsi. B</label
                          >
                          <label
                            class="font-weight-bold mt-2"
                            v-if="index + 1 == 3"
                            >Jawaban Opsi. C</label
                          >
                          <label
                            class="font-weight-bold mt-2"
                            v-if="index + 1 == 4"
                            >Jawaban Opsi. D</label
                          >
                          <label
                            class="font-weight-bold mt-2"
                            v-if="index + 1 == 5"
                            >Jawaban Opsi. E</label
                          >
                          <b-form-checkbox
                            v-if="dataSoal.type == 'multiple_choice'"
                            v-model="data.is_correct"
                            value="1"
                            class="custom-control-primary"
                          >
                            Kunci Jawaban
                          </b-form-checkbox>
                          <b-form-radio-group
                            v-if="dataSoal.type == 'benar_salah'"
                            v-model="data.is_true_or_false"
                            :options="bsSoal"
                            name="radios-stacked"
                            stacked
                          />
                        </b-col>
                        <b-col md="10" v-if="dataSoal.type != 'pg'">
                          <label class="font-weight-bold mt-2"
                            >Upload file format: jpg, png, pdf, doc, mp3, mp4
                            (opsional)</label
                          >
                          <b-form-file
                            :id="'file' + data.id"
                            class="mb-2"
                            v-model="data.opsi_file"
                            placeholder="Pilih file untuk di upload atau unggah..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                          />
                        </b-col>
                        <b-col md="12" v-if="dataSoal.type == 'pg'">
                          <label class="font-weight-bold mt-2"
                            >Upload file format: jpg, png, pdf, doc, mp3, mp4
                            (opsional)</label
                          >
                          <b-form-file
                            :id="'file' + data.id"
                            class="mb-2"
                            v-model="data.opsi_file"
                            placeholder="Pilih file untuk di upload atau unggah..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept=".jpg, .png, .pdf, .doc, .mp3, .mp4"
                          />
                        </b-col>
                        <b-col class="mt-1" md="12" v-if="data.file != null">
                          <!-- <strong>Thumbnail : </strong><br /> -->
                          <b-img
                            :src="apiFile + data.file.file"
                            style="max-width: 500px"
                            :alt="data.file.file"
                          />
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-tab>
                </div>
              </b-tabs>
            </div>
            <div v-if="dataSoal.type == 'pauli'">
              <b-row v-for="(data, index) in item.options" :key="index">
                <b-col md="4">
                  <b-form-group
                    :label="'Baris ' + (index + 1)"
                    :label-for="'h-baris-' + (index + 1)"
                  >
                    <b-form-input
                      :id="'h-baris-' + data.id"
                      placeholder="Isi Soal"
                      v-model="data.content"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    :label="'Jawaban Baris ' + (index + 1)"
                    :label-for="'h-baris-' + (index + 1)"
                  >
                    <b-form-input
                      :id="'h-baris-' + data.id"
                      placeholder="Isi Jawaban"
                      v-model="data.is_correct"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-button
              variant="success"
              @click.prevent="submit"
              class="w-full mt-3"
            >
              Simpan
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
// import 'vue-select/dist/vue-select.css';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BOverlay,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
const limitSoal = 5; // limit pembuatan soal
const mb = 1024;

export default {
  components: {
    vSelect,
    quillEditor,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      mapels: [],
      // default data
      dataSoal: {
        options: [],
        mapel: {
          id: null,
        },
      },
      opsi: null,
      active_soal_attachment: false,
      judul: null,
      selectedOption: 0,
      file: null,
      sum_option: null,
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      optpaket_id: [],
      optmapel_id: [],
      bobot: 0,
      name: null,
      type: "pg",
      mapels: [
        {
          value: "Matematika Kelas X IPA 1",
        },
      ],
      is_correct: null,
      typesSoal: [
        {
          label: "Pilihan Ganda",
          value: "pg",
        },
        {
          label: "Multiple Choice",
          value: "multiple_choice",
        },
        // {
        //   label: "Pauli",
        //   value: "pauli",
        // },
        {
          label: "Pernyataan Benar Salah",
          value: "benar_salah",
        },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      opsisSoal: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      sum_options: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
      masterOptions: [
        {
          id: 1,
          label: "A",
          value: "A",
        },
        {
          id: 2,
          label: "B",
          value: "B",
        },
        {
          id: 3,
          label: "C",
          value: "C",
        },
        {
          id: 4,
          label: "D",
          value: "D",
        },
        {
          id: 5,
          label: "E",
          value: "E",
        },
      ],
      availableOptions: [
        {
          content: "",
        },
      ],
      options: [],
      activeAction: null,
    };
  },
  watch: {
    selectedOption(val) {
      let num = parseInt(val.name);
      this.dataSoal.options.forEach((option, i) => {
        option = {
          id: i,
          key: i,
          index: "Baris" + (i + 1),
          option,
          file: null,
          content: null,
          is_true_or_false: null,
          is_correct: null,
        };
      });
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    addAttachSoal(ind, e) {
      document.getElementById(`file_soal_${ind}`).click();
    },
    addAttachSoalOption(ind, e) {
      document.getElementById(ind).click();
    },
    addSoal() {
      if (this.dataSoal.length >= limitSoal) {
        this.$vs.notify({
          title: "Batas Pembuatan Soal Tercapai",
          text: "",
          variant: "warning",
          position: "top-right",
        });
        return false;
      }

      let data = {
        no: this.dataSoal.length + 1,
        judul: null,
        name: null,
        file: null,
        option: null,
        options: [],
        // is_correct: null,
        bobot: 1,
      };

      if (this.selectedOption != null) {
        for (let i = 0; i < this.selectedOption; i++) {
          let option = this.getOption(i);
          data.options.push({
            key: i,
            option,
            file: null,
            content: null,
          });
        }
      }

      //
      this.dataSoal.push(data);
    },
    updateDataSoal(val) {
      let vm = this;
      this.$route.meta.breadcrumb[2].url =
        "/soal/daftarsoal?mp=" + this.$route.query.mp;
      val.forEach((item, index) => {
        // Change when user choose Jumlah Opsi
        if (item.selectedOption != null) {
          let numOpsi = parseInt(item.selectedOption.name);
          if (item.options.length > 0) {
            item.options = [];
          }

          if (item.options.length < 1) {
            for (let i = 0; i < numOpsi; i++) {
              let option = this.getOption(i);
              vm.dataSoal[index].options[i] = {
                key: i,
                option,
                file: null,
                content: null,
              };
            }
          }
        }
      });
    },
    getEditorOptions(id_file_input) {
      // get event link click in dynamic options
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
            handlers: {
              link: function (val) {
                document.getElementById(id_file_input).click();
              },
            },
          },
        },
      };
    },
    getOption(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },

    getKunjaw(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },
    removeOptions(ind) {
      if (this.options.length > 1) {
        this.options.splice(ind, 1);
      }
    },
    optionNull() {
      let hasOption = this.dataSoal.filter((soal) =>
          soal.options.find(
            (item) =>
              item.content == null || item.content == "" || item.option == null
          )
        ),
        hasNull = 0;

      let find = this.dataSoal.options.find(
        (item) =>
          item.content == null || item.content == "" || item.option == null
      );

      if (find) {
        hasNull++;
      }

      return this.dataSoal.type == "pg" ? hasNull > 0 : false;
    },
    addOption() {
      // untuk menambah opsi
      this.options.push({
        option: null,
        content: null,
      });
    },
    fileToBase64(fileObj) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(fileObj);
      });
    },
    async submit() {
      // method untuk insert soal
      // const dataSoalFile = [],
      //   dataOptionFile = []

      // lakukan validasi jika ada form soal yg belum diisi
      // if (!this.validateForm()) {
      //   this.$vs.notify({
      //     title: 'Tidak dapat menambahkan soal',
      //     text: 'Harap lengkapi data-data yang wajib diisi',
      //     iconPack: 'feather',
      //     icon: 'icon-alert-circle',
      //     variant: 'warning',
      //     position: 'top-right'
      //   })

      //   return false
      // }

      // create var for form data
      const fd = new FormData();
      if (this.dataSoal.type === "pg") {
        // validation option
        // if (this.optionNull()) {
        //   this.$vs.notify({
        //     title: 'Harap isi opsi yang masih kosong',
        //     text: '',
        //     iconPack: 'feather',
        //     icon: 'icon-alert-circle',
        //     variant: 'warning',
        //     position: 'top-right'
        //   })
        //   return false
        // }
      }

      // this.dataSoal.map(async (soal, i) => {
      // append soal to form data
      fd.append(`data[0][id]`, this.dataSoal.id);
      fd.append(`data[0][user_id]`, this.user.id);
      fd.append(`data[0][category_paket_id]`, this.dataSoal.category_paket_id);
      fd.append(`data[0][ujian_id]`, this.$route.query.ujian_id);
      fd.append(`data[0][name]`, this.dataSoal.name);
      fd.append(`data[0][content]`, this.dataSoal.content);
      fd.append(`data[0][bobot]`, this.dataSoal.bobot);
      fd.append(`data[0][pembahasan]`, this.dataSoal.pembahasan);
      fd.append(`data[0][type]`, this.dataSoal.type);

      // if there file soal, append it
      if (this.dataSoal.file != null) {
        fd.append(`data[0][file]`, this.dataSoal.file);
      }

      // if pg, try to append option
      if (this.dataSoal.options.length > 0 && this.dataSoal.type === "pg") {
        this.dataSoal.options.map(async (option, i_opsi) => {
          const answerKey = this.is_correct == option.option ? 1 : 0;
          // const answerKey = soal.is_correct == option.option ? 1 : 0
          fd.append(`data[0][options][${i_opsi}][id]`, option.id);
          fd.append(`data[0][options][${i_opsi}][option]`, option.option);
          fd.append(`data[0][options][${i_opsi}][content]`, option.content);
          fd.append(`data[0][options][${i_opsi}][is_correct]`, answerKey);
          // fd.append(`data[0][options][${i_opsi}][is_correct]`, answerKey)

          // check if there option file attached
          if (option.opsi_file !== null) {
            fd.append(`data[0][options][${i_opsi}][file]`, option.opsi_file);
          }
        });
      }
      if (
        this.dataSoal.options.length > 0 &&
        this.dataSoal.type === "multiple_choice"
      ) {
        this.dataSoal.options.map(async (option, i_opsi) => {
          // const answerKey = this.is_correct == option.option ? 1 : 0
          // const answerKey = soal.is_correct == option.option ? 1 : 0
          fd.append(`data[0][options][${i_opsi}][id]`, option.id);
          fd.append(`data[0][options][${i_opsi}][option]`, option.option);
          fd.append(`data[0][options][${i_opsi}][content]`, option.content);
          if (option.is_correct == 1) {
            fd.append(
              `data[0][options][${i_opsi}][is_correct]`,
              option.is_correct
            );
          }
          if (option.is_correct != 1) {
            fd.append(`data[0][options][${i_opsi}][is_correct]`, 0);
          }

          // check if there option file attached
          if (option.opsi_file !== null) {
            fd.append(`data[0][options][${i_opsi}][file]`, option.opsi_file);
          }
        });
      }
      if (
        this.dataSoal.options.length > 0 &&
        this.dataSoal.type === "benar_salah"
      ) {
        this.dataSoal.options.map(async (option, i_opsi) => {
          // const answerKey = this.is_correct == option.option ? 1 : 0
          // const answerKey = soal.is_correct == option.option ? 1 : 0
          fd.append(`data[0][options][${i_opsi}][id]`, option.id);
          fd.append(`data[0][options][${i_opsi}][option]`, option.option);
          fd.append(`data[0][options][${i_opsi}][content]`, option.content);
          fd.append(
            `data[0][options][${i_opsi}][is_true_or_false]`,
            option.is_true_or_false
          );
          // check if there option file attached
          if (option.opsi_file !== null) {
            fd.append(`data[0][options][${i_opsi}][file]`, option.opsi_file);
          }
        });
      }
      if (this.dataSoal.options.length > 0 && this.dataSoal.type === "pauli") {
        this.dataSoal.options.map(async (option, i_opsi) => {
          // const answerKey = this.is_correct == option.option ? 1 : 0
          // const answerKey = soal.is_correct == option.option ? 1 : 0
          fd.append(`data[0][options][${i_opsi}][id]`, option.id);
          fd.append(`data[0][options][${i_opsi}][option]`, option.index);
          fd.append(`data[0][options][${i_opsi}][content]`, option.content);
          fd.append(
            `data[0][options][${i_opsi}][secret_key]`,
            option.is_correct
          );
          // check if there option file attached
          if (option.opsi_file !== null) {
            fd.append(`data[0][options][${i_opsi}][file]`, option.opsi_file);
          }
        });
      }
      // });

      try {
        this.loading = true;
        await this.$store.dispatch("banksoal/createUpdateDelete", fd);
        this.loading = false;
        (this.activeAction = "edit atau diperbarui"),
          this.displaySuccess({
            text: "Soal berhasil di" + this.activeAction,
          });
        this.$router.push("/master-admin/bank-soal");
        // this.getDataSoal();
        // this.$vs.notify({
        //   title: 'Soal berhasil ditambahkan',
        //   text: 'Silahkan kembali kehalaman soal untuk melihat perubahan',
        //   variant: 'success',
        //   position: 'top-right'
        // })
        // this.clearForm()
      } catch (e) {
        (this.loading = false), this.displayError(e);
        return false;
      }
    },
    checkQuery() {
      if (this.$route.query.s == undefined) {
        this.$router.push({ path: "/soal" });
      }
    },
    handleOptionFile(e, id) {
      // add file dynamic option to data
      let objFile = e.target.files[0];
      this.dataSoal[id.soal].options[id.opsi].file = objFile;

      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[id.soal].options[id.opsi].file =
          URL.createObjectURL(objFile);
      } else {
        this.dataSoal[id.soal].options[id.opsi].file = null;
      }
    },
    handleFile(ind, e) {
      // add soal file to data
      let objFile = e.target.files[0];
      this.dataSoal[ind].file = objFile;
      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.dataSoal[ind].preview_file = URL.createObjectURL(objFile);
      } else {
        this.dataSoal[ind].preview_file = null;
      }
    },
    unhandleFile(ind, e) {
      // remove soal file
      this.dataSoal[ind].file = null;
      this.dataSoal[ind].preview_file = null;
      e.target.value = null;
    },
    unhandleFileOption(e, id) {
      // remove file dynamic option
      this.dataSoal[id.soal].options[id.opsi].file = null;
      this.dataSoal[id.soal].options[id.opsi].preview_file = null;
      e.target.value = null;
    },
    resetHandleFile(ind, e) {
      this.$refs["file_" + ind].value = "";
    },
    resetHandleOptionFile(e, id_file_input) {
      this.$refs[id_file_input].value = "";
    },
    async getMyMapel() {
      const params = {
        guru_id: this.profile.id,
      };
      const mapels = this.$store.dispatch("mapel/getMyMapel", params);
      mapels.map((item) => {
        if (item.mapel) {
          this.mapels.push(item.mapel);
        }
      });
    },
    doFormatRupiah() {
      this.banksoalDataId.price = this.formatRupiah(this.banksoalDataId.price);
      this.banksoalDataId.max_quota = this.formatRupiah(
        this.banksoalDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.banksoalDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    // async getDataById() {
    //   this.$store
    //     .dispatch("banksoal/indexId", this.$route.params.id)
    //     .then((response) => {
    //       let banksoalDataId = response.data;
    //       this.banksoalDataId = banksoalDataId;
    //       this.doFormatRupiah();
    //     })
    //     .catch((error) => {
    //       this.$root.$emit("errorData", error);
    //     });
    // },

    async getDataSoal() {
      this.loading = true;
      this.$store
        .dispatch("banksoal/indexId", this.$route.query.soal_id)
        .then((res) => {
          this.loading = false;
          this.dataSoal = res.data;
          if (this.dataSoal.category_paket_id == 0) {
            this.dataSoal.category_paket_id = 0;
          }
          if (this.dataSoal.category_paket_id > 0) {
            this.dataSoal.category_paket_id = this.dataSoal.kategori_paket.id;
          }
          // this.dataSoal.options = res.data.options;
          this.selectedOption = this.dataSoal.options.length;
          // if (this.dataSoal.options.length > 0)
          if (this.dataSoal.type == "pg") {
            this.is_correct = this.dataSoal.options.find(
              (item) => item.is_correct == 1
            ).option;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataMapel() {
      const payload = {
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };

      this.$store
        .dispatch("mapel/index", payload)
        .then((response) => {
          let optmapel_id = response.data.data;
          optmapel_id.map((item) => {
            item.value = item.id;
            item.text = item.nama_mapel;
          });
          this.optmapel_id = optmapel_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    getDataPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket_id = paket_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  async created() {},
  async mounted() {
    if (this.$route.params.id != null) {
      this.getDataSoal();
    }
    this.getDataMapel();
    this.getDataPaket();
    // this.getDataPaketFAQ();
    //   this.getDataById();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
